<template>
  <div class="checkout">
    <msp-app-bar></msp-app-bar>
    <div id="topscroll"></div>
    <v-alert :type="filteredType"  v-if="error">
      {{error.text}}
    </v-alert>

    <div class="dati"  v-show="cart.prodotti && cart.prodotti.length">
      <v-card>
        <v-card-title>{{paypal.item.name}}</v-card-title>
        <v-card-text>
          <div class="riepilogo">
            <div v-show="cart.prodotti && cart.prodotti.length" class="prodotti" >
              <div v-show="cart.coupon">
                <msp-coupon :coupon="cart.coupon" :prezzo="cart.final_price"></msp-coupon>
              </div>
              <div v-for="prodotto in cart.prodotti" :key="prodotto.id">
                <msp-prodotto-lista :prodotto="prodotto"></msp-prodotto-lista>
              </div>

            </div>

          </div>
          <div v-if="cart.final_price" class="prezzo">
            <p class="sconto-coupon" v-show="cart.coupon">Prezzo finale</p>
            {{cart.final_price}} &euro;
          </div>
          <div v-if="!cart.final_price" class="prezzo">
            <div class="euro">Gratuito</div>
          </div>
          <div v-show="+cart.final_price && !paypal.isLoading" ref="paypal"></div>
          <div v-show="!(+cart.final_price)" class="cart-free">
            <v-btn :disabled = "isDisabled" block class="btn btn-primary" @click="addFreeProducts">Usa il coupon omaggio</v-btn>
          </div>

        </v-card-text>
        <v-card-actions>
        </v-card-actions>
      </v-card>


      <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      v-show="+cart.final_price"
      >
      <v-btn
      small
      block
      @click="getPreviousInvoice()"
      >
      Usa i dati dell'ultima fattura
    </v-btn>

    <v-text-field
    v-model="invoice.nome"
    @change="checkForm"
    label="nome"
    required
    :rules = "[rules.required]"
    />
    <v-text-field
    v-model="invoice.cognome"
    @change="checkForm"
    label="cognome"
    required
    :rules = "[rules.required]"
    />
    <v-select
    :items="nazioni"
    label="nazione"
    v-model="invoice.nazione"
    @change="updateNazione"
    required
    :rules = "[rules.required]"
    ></v-select>
    <v-text-field
    v-model="invoice.indirizzo"
    @change="checkForm"
    label="indirizzo"
    required
    :rules = "[rules.required]"
    />
    <v-text-field
    v-model="invoice.civico"
    @change="checkForm"
    label="civico"
    required
    :rules = "[rules.required]"
    />
    <v-text-field
    v-model="invoice.citta"
    @change="checkForm"
    label="citta"
    required
    :rules = "[rules.required]"
    />
    <v-text-field
    v-model="invoice.provincia"
    @change="checkForm"
    label="provincia"
    required
    :rules = "[rules.required]"
    />
    <v-text-field
    v-model="invoice.cap"
    @change="checkForm"
    label="cap"
    required
    :rules = "[rules.required]"
    />
    <v-text-field
    v-model="invoice.cf"
    @change="checkForm"
    label="cf"
    required
    :rules = "[rules.required, rules.cf]"
    />
    <v-text-field
    v-model="invoice.email"
    @change="checkForm"
    label="email"
    required
    :rules = "[rules.required, rules.email]"
    />
    
  <v-radio-group
  v-model="invoice.ck_cod_univoco"
  :rules = "[rules.required]"
  row
  @change="updateCodiceUnivoco(); checkForm()"
  >
  <template v-slot:label>
    <div>Codice univoco</div>
  </template>
  <v-radio
  label="no"
  value="no"
  ></v-radio>
  <v-radio
  label="sì"
  value="si"
  ></v-radio>
</v-radio-group>
<v-text-field
v-model="invoice.cod_univoco"
@change="checkForm"
label="Codice univoco"
:rules = "[rules.condition(invoice.ck_cod_univoco)]"
/>



<v-radio-group
v-model="invoice.ck_piva"
@change="checkForm"
:rules = "[rules.required]"
row
>
<template v-slot:label>
  <div>P.IVA</div>
</template>
<v-radio
label="no"
value="no"
></v-radio>
<v-radio
label="sì"
value="si"
></v-radio>
</v-radio-group>
<v-text-field
v-show="invoice.ck_piva === 'si'"
v-model="invoice.piva"
@change="checkForm"
label="P.IVA"
:rules = "[rules.condition(invoice.ck_piva)]"
/>



<v-btn
color="success"
class="mr-4"
@click="submit"
>
torna su
</v-btn>
</v-form>



</div>
</div>
</template>

<script type="module">
import Cookies from 'js-cookie';
import mspAppBar from "@components/MspAppBar.vue";
import checkCF from "@src/js/CodiceFiscale.js";
import mspCoupon from "@components/MspCoupon.vue";
import mspProdotto from "@components/MspProdotto.vue";
import mspProdottoLista from "@components/MspProdottoLista.vue";
import env from "@mixins/environment.js"
import _api from "@mixins/api.js";
import { loadScript } from '@paypal/paypal-js';

const component = {
  mixins: [
    env,
  ],
  components: {
    mspAppBar,
    mspCoupon,
    mspProdotto,
    mspProdottoLista,
  },
  data: function () {
    const data = {
      isDisabled: true,
      nazioni: [
        'Italia',
        'Estero',
        ],

      valid: true,
      error: {
        type: null,
        text: null,
        code: null,
      },
      messages: {
        'OKACQUISTO': 'Acquisto completato con successo.',
        'ERRORACQUISTO': 'Si è verificato un problema in fase di acquisto. Contatta lo staff di CoachPeaking.',
        'ONPAGAMENTO': 'Pagamento in corso...',
        'ERRORPAGAMENTO': 'Errore nell\'acquisto.',
        'CANCELPAGAMENTO': 'Pagamento annullato.',
      },

      cart:{
        coupon: {},
        prodotti: [],
        finalPrice: null,
      },
      emptyCart: null,
      paypal: {
        isLoading: false,
        amount: {
          "currency_code":"EUR",
          "value":1.22,
          "breakdown":{
            "item_total":{
              "currency_code":"EUR",
              "value":1
            },
            "shipping":{
              "currency_code":"EUR",
              "value":0
            },
            "tax_total":{
              "currency_code":"EUR",
              "value":0.22
            }
          },
        },
        item: {
          "name": "",
          "description": "",
          "quantity": "",
          "price": "",
          "currency": "EUR"
        },
        style: {
          shape: 'pill',
          color: 'gold',
          layout: 'horizontal',
          label: 'paypal',
        },
        credentials: process.env.VUE_APP_PAYPAL_CLIENT_ID,
      },
      emailRules: [
      ],

      rules: {
        required: value => !!value || 'Richiesto.',
        min: v => (v && v.length >= 5) || 'Min 5 caratteri',
        email: v => /.+@.+/.test(v) || 'L\'e-mail deve essere valida',
        cf: v => ((!this.isItalia() || checkCF(v))) || 'Il codice fiscale non è valido',
        condition: (ck)=>((value)=>((ck==='no') || !!value || 'Richiesto')),
        emailMatch: () => ('The email and password you entered don\'t match')
      },

      invoice: {
        nome: null,
        cognome: null,
        indirizzo: null,
        civico: null,
        citta: null,
        provincia: null,
        cap: null,
        nazione: null,
        cf: null,
        email: null,
        cod_univoco: null,
        ck_cod_univoco: null,
        piva: null,
        ck_piva: null,
      },
    }
    return data;
  },
  mounted() {
    this.init();
  },
  computed: {
    filteredType: function ()
    {
      let default_type = 'info';
      let types = {
        'success': 'success',
        'alert': 'warning',
        'error': 'error',
        'danger': 'error',
      };
      let type = this.error && this.error.type;
      if (!type)
      {
        return default_type;
      }
      let filtered = types[type];
      if (!filtered)
      {
        return default_type;
      }
      return filtered;

    }
  },
  methods: {
      initCart () {
          this.emptyCart = {...this.cart};
      },
      clearCart () {
          this.cart = {...this.epmtyCart}
      },
      paymentCompleted (data) {
          this.paypal.isLoading=false;
          if (data.message) {
              this.showMessage({
                  success:data.success,
                  message:data.message
              });

          }
          if (data.success) {
              this.clearCart();
              this.openCalendar();
          }
      },
      openCalendar: function () {
          this.$router.push('/calendario/');
      },

      showMessage (data) {
          let type = (data.success) ? 'info' : 'error';
          this.error = {
              type: type,
              text: data.message
          }

      },
      updateCodiceUnivoco () {
          if (this.invoice.ck_cod_univoco === 'no') {
              this.invoice.cod_univoco = '0000000';
          }
      },

      isItalia: function () {
          const valid = [
              'italia',
              'ita',
              'it',
          ];
          if (!this.invoice.nazione) return false;
          const value = this.invoice.nazione.toLowerCase();
          const isItalia = (valid.indexOf(value) > -1);
          return isItalia;
      },

      normalizeItalia: function () {
          if (this.isItalia()) {
              this.invoice.nazione = "Italia";
          }
      },

      setDatiEstero: function () {
          this.invoice.ck_cod_univoco = 'si';
          this.invoice.cod_univoco="XXXXXXX";
          this.invoice.cap = "00000";
          this.invoice.cf = "XXXXXXXXXXX";
          this.invoice.provincia = "EE";
      },

      setDatiItalia: function () {
          this.normalizeItalia();
          if (this.invoice.cod_univoco === "XXXXXXX") {
              this.invoice.cod_univoco = "";
          }
          if (this.invoice.cap === "00000") {
              this.invoice.cap = "";
          }
          if (this.invoice.provincia === "EE") {
              this.invoice.provincia = "";
          }
      },

      updateNazione: function () {
          if (!this.isItalia()) {
              this.setDatiEstero();
          } else {
              this.setDatiItalia();
          }
          this.checkForm();
      },

      checkForm () {
          let form = this.$refs.form;
          if (!form.validate()) {
              this.error = {
                  type: "error",
                  text: "Controlla i dati per la fattura."
              };
              return false;
          }
          else
          {
              this.error = null;
              return true;

          }

      },
      scrollToTop: function () {
          let top = this.$el.querySelector("#topscroll");
          top.scrollIntoView();
      },
      submit () {
          this.scrollToTop();
          this.checkForm();

      },
      initPaypal: function () {
          let self = this;
          this.$nextTick(()=>{

              const container = self.$refs.paypal
              loadScript({
                  'currency': 'EUR',
                  'client-id': this.paypal.credentials
              }).then(
              (paypal) => {
                  document.querySelector("#app").scrollTo(0,0);

                  paypal.Buttons({
                      style: self.paypal.style,

                      onClick: function() {
                          self.paypal.isLoading=true;
                          self.showMessage({
                              success:true,
                              message:self.messages.ONPAGAMENTO
                          });


                          let isValid = self.checkForm();
                          if (!isValid) {
                              self.paypal.isLoading=false;
                              document.querySelector("#app").scrollTo(0,0);

                              return false;
                          }

                      }, // onClick

                      createOrder: function(data, actions) {
                          // This function sets up the details of the transaction, including the amount and line item details.

                          return actions.order.create({
                              purchase_units: [{
                                  amount: {
                                      "value": self.cart.final_price,
                                      "currency_code":"EUR"
                                  },
                                  description: self.cart.prodotti.map(el=>el.nome).join("; "),
                              }]
                          });
                      }, // createOrder
                      onCancel: function() {
                          self.paymentCompleted({
                              success:false,
                              message:self.messages.CANCELPAGAMENTO
                          });

                      },
                      onApprove: function(data, actions) {
                          self.showMessage({
                              success:true,
                              message:self.messages.ONPAGAMENTO
                          });

                          return actions.order.capture().then(
                              (details) =>{
                                  const esito = details;
                                  // const json_ordine = leggiCookie('json_ordineCoachpeaking');

                                  if (details.status == "COMPLETED" && details.intent == "CAPTURE") {

                                      const data = {
                                          esito: esito,
                                          final_price: self.cart.final_price,
                                          prodotto_ids: self.cart.prodotti.map(el=>el.id),
                                              invoice: self.invoice,
                                      };


                                      const url = self.api_base_url+"/api/v2/ecommerce.php?paypal";

                                      return new Promise((resolve,reject)=>{
                                          _api(url, 'POST', data)
                                              .then((result)=>{
                                                  self.paypal.isLoading=false;
                                                  let result_completed = {
                                                      success: true,
                                                      message: self.messages.OKACQUISTO
                                                  }
                                                  self.paymentCompleted(result_completed);

                                                  resolve(result);
                                              })
                                              .catch((error)=>{
                                                  self.paypal.isLoading=false;
                                                  let result_completed = {
                                                      success: false,
                                                      message: self.messages.ERRORACQUISTO
                                                  }
                                                  self.paymentCompleted(result_completed);

                                                  reject(error);
                                              });
                                      });
                                  }
                                  else {
                                      let result_completed = {
                                          success: false,
                                          message: self.messages.ERRORPAGAMENTO
                                      }
                                      self.paymentCompleted(result_completed);

                                  }
                              })
                      },
                      onError: function(err) {
                          self.paypal.isLoading=false;
                          let result_completed = {
                              success: false,
                              message: self.messages.ERRORPAYMENT
                          }
                          self.paymentCompleted(result_completed);
                          console.log(err);
                      }
                  }).render(container);
              });
          });

      },
      init: function () {
          this.loadUtente();
          this.loadCheckout();
          this.initCart();
          this.initPaypal();
      },
      validate () {
          this.$refs.form.validate()
      },
      reset () {
          this.$refs.form.reset()
      },
      resetValidation () {
          this.$refs.form.resetValidation()
      },

      mapFieldsUtente: function (data) {
          let invoice = {};
          const maps = {
              "nome": "nome",
              "cognome": "cognome",
              "cf": "cod_fiscale",
              "email": "email",
              // "iban": "iban",
              "citta": "res_comune",
              "provincia": "res_prov",
              "cap": "res_cap",
              "indirizzo": "res_indirizzo",
              "nazione": "res_nazione",
          }
          for(let key in maps) {
              let value = data[maps[key]];
              if (value) {
                  invoice[key] = value;
              }
          }
          return invoice;
      },
      loadUtente () {

          let url = this.api_base_url+"/api/v2/utente.php";//+params;

          return new Promise((resolve,reject)=>{
              _api(url, "GET")
                  .then((result)=>{

                      if (result.success) {

                          this.invoice = Object.assign(
                              {},
                              this.invoice,
                              this.mapFieldsUtente(result.data)
                          );
                      }
                      resolve(result);
                  })
                  .catch((error)=>{
                      reject(error);
                  });
          });

      },
      getPreviousInvoice () {

          let url = this.api_base_url+"/api/v2/ecommerce.php?pagamenti";//+params;

          return new Promise((resolve,reject)=>{
              _api(url, "GET")
                  .then((result)=>{

                      if (result.success) {
                          const purchases = result.data;
                          if (!purchases.length) {
                              resolve(false);
                          }

                          const lastPurchase = result.data[0];
                          let invoice = lastPurchase.invoice;
                          if(invoice) {
                              const regex = /(i_int_|i_fat_)/gi;

                              if (!Object.keys(invoice).indexOf("nome")===-1) {

                                  invoice = invoice.map((el)=>{
                                      let newName = el.name.replace(regex, '');
                                      el.name = newName;
                                      return el;
                                  });
                                  let keys = Object.keys(this.invoice).filter(el=>el!=='coupon');
                                  invoice.filter((el)=>(keys.indexOf(el.name)!==-1)).forEach(
                                      (el)=>{
                                          let updated = {}
                                          updated[el.name] = el.value
                                          this.invoice = Object.assign(
                                              {},
                                              this.invoice,
                                              updated
                                          );
                                      }
                                  );
                              } else {
                                  delete invoice.coupon;
                                  this.invoice = Object.assign({},this.invoice,invoice);

                              }
                              this.normalizeItalia();
                          }
                          resolve(true);
                      } else {
                          resolve(false);
                      }
                      // checkForm();
                  })
                  .catch((error)=>{
                      reject(error);
                  });

          });
      },

      /*
applicaCoupon: function () {
if (!(this.invoice.coupon && this.cart.prodotti && this.cart.prodotti.length)) {
return;
}
let prodotto_ids=this.cart.prodotti.map(el=>el.id).join(",");
MSP.api.get("ecommerce.php?checkout&coupon="+this.invoice.coupon+"&prodotto="+prodotto_ids, "/api/v2")
    .then(
    (result)=>{
    this.parseCheckout(result);

    }
    )
    },


       */

      parseCheckout: function (result) {
          if (result.success) {
              if (result.data.error_object && result.data.error_object.type==="error") {
                  this.isDisabled = true;
                  this.error = Object.assign({}, result.data.error_object);
                  this.cart = Object.assign({}, {});
                  return;
              }
              else
              {
                  if (result.data.error_object) {
                      this.error = Object.assign({}, result.data.error_object);
                      this.isDisabled = true;
                  } else {
                      this.error = null;
                      this.isDisabled = false;
                  }

                  this.cart = Object.assign({}, result.data);
              }
          }
          else
          {
              this.isDisabled = false;
              this.error = null;
              this.cart = null;

          }
      },



      loadCheckout: function () {
          let params = {
              checkout: 1,
          };


          let prodotto = this.$route.query['prodotto'];

          let coupon;
          if (this.$route.query['coupon']) {
              coupon = this.$route.query['coupon'];
          } else if (Cookies.get('CP_COUPON')) {
              coupon = Cookies.get('CP_COUPON');
              window.location.search="coupon="+coupon;
              Cookies.remove('CP_COUPON');
          }


          if (coupon) {
              params.coupon = coupon
          }
          if (prodotto) {
              params.i = btoa(JSON.stringify({"idArticolo":prodotto}));
          }
          let a_list = [];
          for (let k in params) {
              a_list.push(k+"="+params[k]);
              if (k==='coupon') {
                  this.invoice.coupon = params[k];
              }
          }


          // let list = encodeURI(a_list.join("&"));
          let list = a_list.join("&");

          const url = this.api_base_url+"/api/v2/ecommerce.php?"+list;
          _api(url, "GET")
          .then((result)=>{
              this.parseCheckout(result);
          });

      },

      addFreeProducts: function () {

          const data = {
              final_price: this.cart.final_price,
              prodotto_ids: this.cart.prodotti.map(el=>el.id),
                  invoice: this.invoice,
          };
          const url = this.api_base_url+"/api/v2/ecommerce.php";
          this.isDisabled = false;

          return new Promise(
              (resolve,reject)=>{
                  _api(url, 'POST', data)
                  .then((result)=>{

                      let data = {
                          success:true,
                          message:this.messages.OKACQUISTO
                      };
                      this.paymentCompleted (data);
                      resolve(result);
                  })
                  .catch((error)=>{
                      this.isDisabled = false;

                      this.showMessage({
                          success:false,
                          message:this.messages.ERRORACQUISTO
                      });

                      reject(error);
                  });
              }
          );
      },


      /** carica i checkout:
       */
      loadProdotti: function (coupon) {
          const url = this.api_base_url+"/api/v2/ecommerce.php?coupon="+coupon;//+params;

          return new Promise((resolve,reject)=>{
              _api(url, "GET")
                  .then((result)=>{
                      if (result.success) {
                          resolve(result);
                      } else {
                          resolve(null);
                      }
                  })
                  .catch((error)=>{
                      reject(error);
                  })
          });
      },


  }
}
export default component
</script>
<style lang="scss">
.checkout {
  padding: 20px;
  .paypal-buttons iframe{
    z-index: 1 !important;
  }
  .sconto-coupon {
    text-align: center;
    padding: 0;
    font-size: 0.8em;
  }
  .prezzo {
    border: 1px solid var(--col-grigio);
    border-width: 1px 0;
    text-align: center;
    font-weight: bold;
    font-size: 2em;
    line-height: 1;
    padding: 10px;
    margin: 10px 0;

  }

  #topscroll {
      width: 100%;
      height: 70px;
      opacity: 0;
      pointer-events: none;
      margin-top: -70px;
  }
}
</style>
<!--  -->
