<template>
  <div class="form-coupon">
  <template v-if="coupon">
  <h3>{{coupon.codice}}</h3>
  <div class="coupon-content">
  <div v-if="coupon.descrizione" class="coupon-descrizione pre">{{coupon.descrizione}}</div>
  <div  v-if="prezzo !== null" class="coupon-finalprice"><span>Prezzo finale:</span> <span><strong>{{prezzo}}</strong><span class="euro"> euro</span></span></div>
  </div>
  </template>
  </div>
</template>
<script>
const component = {
  props: [
    'coupon',
    'prezzo'
  ],

}

export default component;
</script>
<style lang="scss">

.form-coupon {
  --padding: 10px;
  text-align: left;
  max-width: 600px;
  font-size: 1em;
  padding: var(--padding);
  h3 {
    font-size: 1.2em;
  }
  .coupon-descrizione {
    margin-bottom: var(--padding);
  }
  .coupon-finalprice {
    display: flex;
    justify-content: space-between;
    font-size: 1.2em;
    background: #fff;
    padding: 5px;
    border-radius: 5px;
    .euro {
      font-size: 0.5em;
    }

  }

  margin: 20px auto;
  border: 1px solid var(--col);
  border-radius: 5px;
  background: #fff;
  box-shadow: 0 0 5px rgba(0,0,0,0.4);
  color: #000;
  background: var(--col-ui-evidenza-chiaro);
  form {
    margin: 0;
    padding: var(--padding);
    display: flex;
    .coupon-input {
      padding: 5px;
      font-size: 1.2em;
      font-weight: bold;
      flex: 1;
      margin-right: var(--padding);

    }
  }


}
</style>
