<template>
  <v-card
  class="mb-5"
  max-width="400"

  >
  <v-card-title>{{prodotto.nome}}</v-card-title>
  <v-card-subtitle>{{prodotto.descrizione}}</v-card-subtitle>
  <v-card-text>

    <div class="prodotto-opzioni">

      <v-chip v-if="prodotto.giorni" class="prodotto-opzione prodot-giorni">giorni: <strong>{{prodotto.giorni}}</strong></v-chip>

    </div>
    <div v-if="prodotto.prezzo" class="my-4 subtitle-1">
      <span class="euro">&euro;</span> {{prodotto.prezzo}}
      <span class="prodotto-iva">({{netto}} + IVA &euro;{{iva}})</span>
     </div>
     <div v-if="!prodotto.prezzo" class="my-4 subtitle-1">
       <div class="euro">Gratuito</div>
     </div>
    </v-card-text>
    <v-card-actions>
      <v-btn @click="open(prodotto)" block large color="primary">
      <span v-if="!prodotto.prezzo">Ottieni</span>
      <span v-else >Acquista</span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import menu from "@mixins/menu.js"
import version from "@mixins/version.js"
import env from "@mixins/environment.js"

const component = {
  props: [
    'prodotto',
  ],
  computed: {
    netto ()
    {
      const netto = Math.round(this.prezzo / (1+this.aliquota) * 100) / 100;
      return netto;
    },
    prezzo ()
    {
      const prezzo = Math.round(+this.prodotto.prezzo*100)/100;
      return prezzo;
    },
    aliquota ()
    {
      const aliquota = Math.round(+this.prodotto.iva/100*100)/100;
      return aliquota;
    },
    iva ()
    {
      const iva = Math.round((this.prezzo - this.netto)*100)/100;
      return iva;
    }
  },
  mixins: [
    env,
    menu,
    version,
  ],
  methods: {
    open (prodotto)
    {
      this.$router.push({name: 'checkout', query: {prodotto: prodotto.id}});
    }
  }
}

export default component;
</script>
<style lang="scss">
.prodotto {

  .v-card__title {
    word-break: normal;
  }
  .v-btn__content {
    flex-direction: column;
    white-space: wrap;
  }
  .prodotto-iva {
    font-size: 0.9em;
  }
}
</style>
