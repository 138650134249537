<template>
  <v-card color="var(--col-ui-evidenza-chiaro)" class="prodotti-coupon mb-5">
    <v-card-title>
      Usa un coupon

    </v-card-title>
    <v-card-text>
      <v-text-field
      :value="coupon"
      @input="uppercase"
      :rules="[rules.required, rules.min]"
      counter="8"
      minlength="8"
      maxlength="8"
      />
  </v-card-text>
  <v-card-actions>
    <v-btn    :disabled="!formIsValid"
    @click="submit">usa il coupon</v-btn>

  </v-card-actions>
  </v-card>
</template>
<script>
const component = {
  props: [
  ],
  data()
  {
    return {
      'coupon': '',
      rules: {
         required: value => !!value || 'Richiesto.',
         min: v => v.length === 8 || 'Inserisci 8 caratteri',
       },

    }
  },
  computed: {
    formIsValid () {
       return this.coupon.length === 8;
    },
  },
  methods: {

    uppercase(value)
    {
      this.coupon = value.toUpperCase();
    },
    submit()
    {
      this.$emit("submit",{coupon: this.coupon});
    }
  }
}

export default component;
</script>
<style lang="scss">

.form-coupon {
  --padding: 10px;
  text-align: left;
  max-width: 600px;
  font-size: 1em;
  padding: var(--padding);
  h3 {
    font-size: 2em;
  }
  .coupon-descrizione {
    margin-bottom: var(--padding);
  }
  .coupon-finalprice {
    display: flex;
    justify-content: space-between;
    font-size: 2em;
    background: #fff;
    padding: 5px;
    border-radius: 5px;
    .euro {
      font-size: 0.5em;
    }

  }

  margin: 20px auto;
  border: 1px solid var(--col);
  border-radius: 5px;
  background: #fff;
  box-shadow: 0 0 5px rgba(0,0,0,0.4);
  color: #000;
  background: var(--col-ui-evidenza-chiaro);
  form {
    margin: 0;
    padding: var(--padding);
    display: flex;
    .coupon-input {
      padding: 5px;
      font-size: 2em;
      font-weight: bold;
      flex: 1;
      margin-right: var(--padding);

    }
  }


}
</style>
