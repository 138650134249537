<template>
  <div class="shop">
    <msp-app-bar></msp-app-bar>
    <div class="prodotti">
    
    <msp-licenza-scaduta></msp-licenza-scaduta>

      <msp-form-coupon @submit="submitCoupon"></msp-form-coupon>
      <div class="prodotto" v-for="prodotto in prodotti" :key="prodotto.id">
        <msp-prodotto
        :prodotto="prodotto"
        ></msp-prodotto>
      </div>
    </div>
  </div>

</template>

<script type="module">
import mspAppBar from "@components/MspAppBar.vue";
import mspProdotto from "@components/MspProdotto.vue";
import mspFormCoupon from "@components/MspFormCoupon.vue";
import mspLicenzaScaduta from "@components/MspLicenzaScaduta.vue";
import api from "@mixins/api.js"
import env from "@mixins/environment.js"


const component = {
  mixins: [
    env,
  ],
  components: {
      mspLicenzaScaduta,
      mspAppBar,
      mspProdotto,
      mspFormCoupon,

  },
  data: function () {
    const data = {
      prodotti: []
    }
    return data;
  },
  computed: {
  },
  mounted() {
    this.init();
  },
  methods: {
    init: function () {
      this.loadProdotti()
      .then( (result)=>{
          this.prodotti = result.data;
        })
    },

    submitCoupon({coupon}) {
        this.$router.push({name: 'checkout', query: {coupon: coupon}});
    },

    /** carica i prodotti: */
    loadProdotti: function () {
      let url = this.api_base_url+"/api/v2/ecommerce.php";
      return api(url, "get");
    },


  }
}
export default component
</script>
<style lang="scss">
.prodotti {
  background: var(--col-msp);
  padding: 20px;
}
</style>
